import React, { useEffect } from "react"
import Layout from "../components/layout"
import { Seo } from "../components/index"
import { SEOTitle, Container, Wrapper, Column, Text } from "../theme"

const ScheduleConsultation = () => {
  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  })

  return (
    <Layout title="Book a Free Consultation">
      <Wrapper>
        <Seo
          title="Schedule a Consultation with CheshTech"
          description="Schedule a free consultation with the CheshTech team to find out how we can help you acquire more leads, close more deals, and generate more revenue."
        />
        <Container>
          <Column width="80%" margin="0 auto">
            <SEOTitle margin="0 0 10px 0">What do we chat about?</SEOTitle>
            <Text black textAlign="left">
              The initial call will be all about you and your business. We want
              to learn as much as possible about your company and what you do.
              We'll talk about the problems your company is facing and we'll
              provide a solution and outline of a plan of how we can take action
              to solve that problem.
            </Text>
            <Text black textAlign="left">
              Afterwards, we'll put together a customized solution for you and
              we'll go over it. At that point, you'll determine if that's the
              best solution for you and we'll begin the process of working
              together!
            </Text>
            <div
              className="fadeIn calendly-inline-widget"
              data-url="https://calendly.com/cheshtech/30-minute-web-app-podcast-marketing-consultation"
              style={{
                minWidth: 320,
                height: 630,
                width: "100%",
                minHeight: "100%",
                marginBottom: "4em",
                marginTop: "30px",
              }}
            ></div>
          </Column>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default ScheduleConsultation
